import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import PostCompanyRequestModel from '../../../../services/v2/model/post-company-request-model';
import personalDetailsVueSchema from './createCompanies.vue.schema';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';

export default class CreateCompanysPage extends PageBase {
  public company = new PostCompanyRequestModel();

  public error?: Error | string = undefined;

  public emailError: string | null = null;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanies,
    insertCommand: Vue.$service.v2.api.tdg_Companies.postCompany,
  });

  async initialize(): Promise<void> {
    this.companiesDataSource.filter.searchCriteria = '';
    await this.companiesDataSource.select();
  }

  public saveNewCompanies = async () => {
    const valid = this.company.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadTdgDocumentRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
        this.company.logoDocumentExternalId = documentId.data;
      }
      try {
        await this.companiesDataSource.insert(this.company);
        Vue.$router.push('/_admin/companies');
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };
}
