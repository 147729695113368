<template>
  <p-container>
    <h2 class="mb-4">
      <p-button tertiary @click="$router.back()">
        <p-icon>keyboard_backspace</p-icon>
      </p-button>
      {{ $t('page.createOrganisation.title.addOrganisation') }}
    </h2>
    <p-row>
      <p-col>
        <p-row>
          <p-card>
            <p-row column>
              <p-col>
                <p-form :model="page.organisation">
                  <h3 class="mb-2">
                    {{ $t('page.createOrganisation.title.organisationAdress') }}
                  </h3>
                  <p-text-field v-model="page.organisation.organisationName" :label="$t('page.createOrganisation.label.organisationName')" required></p-text-field>
                  <p-row>
                    <p-col xs12 sm10>
                      <p-text-field v-model="page.organisation.organisationStreet" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
                    </p-col>
                    <p-col xs12 sm2>
                      <p-text-field v-model="page.organisation.organisationHouseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
                    </p-col>
                  </p-row>
                  <p-row>
                    <p-col xs12 sm3>
                      <p-text-field v-model="page.organisation.organisationZIP" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
                    </p-col>
                    <p-col xs12 sm9>
                      <p-text-field v-model="page.organisation.organisationCity" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
                    </p-col>
                  </p-row>
                  <p-file-drop
                    v-model="page.logoDocument"
                    :label="$t('page.createOrganisation.label.LogoDocumentId')"
                    max="7"
                    accept="image/*"
                    single
                  ></p-file-drop>
                  <p-number-field v-model="page.organisation.membersCount" :label="$t('page.createOrganisation.label.membersCount')" required></p-number-field>
                  <h3 class="mb-2">
                    {{ $t('page.createOrganisation.title.organisationContactDetails') }}
                  </h3>
                  <p-select v-model="page.organisation.organisationContactSalutation" :label="$t('page.createOrganisation.label.salutation')" :items="page.genderOptions()" required></p-select>
                  <p-text-field v-model="page.organisation.organisationContactFirstName" :label="$t('page.createOrganisation.label.firstName')" required></p-text-field>
                  <p-text-field v-model="page.organisation.organisationContactLastName" :label="$t('page.createOrganisation.label.lastName')" required></p-text-field>
                  <p-phone-field v-model="page.organisation.organisationContactPhoneNumber" :label="$t('page.createOrganisation.label.phoneNumber')" required type="tel"></p-phone-field>
                  <p-text-field v-model="page.organisation.organisationContactEmail" :label="$t('page.createOrganisation.label.email')" required type="email"></p-text-field>
                  <p-row>
                    <p-toggle v-model="page.organisation.isActive" :label="$t('page.createOrganisation.label.isActive')" color="success"></p-toggle>
                  </p-row>
                </p-form>
                <p-request-alert v-model="page.error"></p-request-alert>
                <p-row>
                  <p-button @click="$router.back()">
                    {{ $t('core.app.cancel') }}
                  </p-button>
                  <p-button @click="page.saveNewOrganisations()">
                    {{ $t('core.app.save') }}
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-card>
        </p-row>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CreateOrganisationsPage from './createOrganisations.vue.model';

  export default Vue.extend({
    data: () => ({
      page: new CreateOrganisationsPage(),
      isLoading: false,
      search: undefined,
      selected: undefined,
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>
