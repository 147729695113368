import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import { WidgetConfig } from '../../../../../components/il-widget-tester.vue.model';

export default class CompanyRegistrationTestingPage extends PageBase {
  get widgetConfig() {
    return {
      route: { value: '/_widget/companies/registration', hidden: true },
      'widget-detailurl': { label: 'Detail Url', value: '/_admin/testing/widget/companies/registration' },
      'widget-successelementid': { label: 'Success ID', default: 'widget-success' },
    } as WidgetConfig;
  }

  async initialize(): Promise<void> {
    // Not used
  }
}
