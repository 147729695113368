import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import PostOrganisationRequestModel from './post-organisation-request-model';
import RegisterRequestModel from './register-request-model';
import PostOrganisationRequestDTO from '../_generated/dto/post-organisation-request-dto';
import RegisterRequestDTO from '../_generated/dto/register-request-dto';
import PostUserRequestDTO from '../_generated/dto/post-user-request-dto';
import PostUserRequestModel from './post-user-request-model';

export default class OrganisationAndUserRequestModelBase extends BaseModel<PostOrganisationRequestDTO & RegisterRequestDTO & PostUserRequestDTO> {
  public organisation = new PostOrganisationRequestModel();

  public user = new RegisterRequestModel();

  public tdg_User = new PostUserRequestModel();
}
