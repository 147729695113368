<template>
  <p-container>
    <p-row column>
      <p-col justify-center>
        <h2>TourDeGwaerb - IL</h2>
      </p-col>
      <p-col justify-center>
        <span class="caption-1"></span>
      </p-col>
      <p-col justify-center>
        <div class="my-3">
          <span>
            For more information on how to get rolling see the <a rel="noopener noreferrer" target="_blank" href="https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/200/Getting-Started">getting started guide</a>
          </span>
        </div>
      </p-col>
      <p-col justify-center>
        <p-img
          class="glit-logo"
          width="300"
          contain
          :src="require('@src/assets/logo.png')"
        ></p-img>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
  });
</script>
