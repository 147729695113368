import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import router from './router';
import servicesV2 from './services/v2';
import ilLanguageSelection from './components/il-language-selection.vue';

Vue.config.productionTip = false;
// Core entry point
Vue.use(core, {
  // appId: 'custom-mount-id'
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(servicesV2);

// COMPONENTS
Vue.component('IlLanguageSelection', ilLanguageSelection);

// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {
    'widget-successelementid'?: string
  }
}
