// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/post-organisation-request-schema',
  type: 'object',
  required: ['organisationName', 'membersCount', 'organisationContactSalutation', 'organisationContactFirstName', 'organisationContactLastName', 'organisationContactPhoneNumber', 'organisationContactEmail', 'organisationStreet', 'organisationZIP', 'organisationCity', 'acceptConditions'],
  properties: {
    organisationName: {
      type: 'string',
      minLength: 1,
    },
    logoDocumentExternalId: {
      type: 'string',
      nullable: true,
    },
    membersCount: {
      type: 'integer',
    },
    organisationContactSalutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    organisationContactFirstName: {
      type: 'string',
      minLength: 1,
    },
    organisationContactLastName: {
      type: 'string',
      minLength: 1,
    },
    organisationContactPhoneNumber: {
      type: 'string',
      minLength: 1,
    },
    organisationContactEmail: {
      type: 'string',
      minLength: 1,
    },
    organisationStreet: {
      type: 'string',
      minLength: 1,
    },
    organisationHouseNumber: {
      type: 'string',
      nullable: true,
    },
    organisationZIP: {
      type: 'string',
      minLength: 1,
    },
    organisationCity: {
      type: 'string',
      minLength: 1,
    },
    isActive: {
      type: 'boolean',
    },
    acceptConditions: {
      type: 'boolean',
    },
    organisationUserId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
