// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UploadTdgDocumentRequestModel from '../../model/upload-tdg-document-request-model';
import UploadTdgDocumentRequestDTO from '../dto/upload-tdg-document-request-dto';
import dtoSchema from '../schemas/upload-tdg-document-request-schema';

export default abstract class UploadTdgDocumentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UploadTdgDocumentRequestDTO>) {
    super();
    if (data) {
      this.uploadFile = data.uploadFile;
    }
  }
  /**
  * @type {binary}
  */
  uploadFile?: File;

  static toModel(dto: DeepPartial<UploadTdgDocumentRequestDTO>): UploadTdgDocumentRequestModel;
  static toModel(dto: DeepPartial<UploadTdgDocumentRequestDTO> | undefined | null): UploadTdgDocumentRequestModel | undefined;
  static toModel(dto: DeepPartial<UploadTdgDocumentRequestDTO> | undefined | null): UploadTdgDocumentRequestModel | undefined {
    return dto ? new UploadTdgDocumentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UploadTdgDocumentRequestModel> | UploadTdgDocumentRequestModel): UploadTdgDocumentRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      uploadFile: unwrapped.uploadFile,
    } as UploadTdgDocumentRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UploadTdgDocumentRequestModel, can not map to UploadTdgDocumentRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
