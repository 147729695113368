<template>
  <p-container>
    <!-- Title -->
    <h2 class="mb-4">
      {{ $t('navigation.page.companies.title') }}
    </h2>
    <p-row>
      <!-- Search Field -->
      <p-col xs12 md6 lg2>
        <p-text-field v-model="page.companiesDataSource.filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search"></p-text-field>
      </p-col>
      <p-col xs12 md6 lg2>
        <p-select
          v-model="page.companiesDataSource.filter.selectedOrganisationId"
          clearable
          :label="$t('page.companies.select.organisationName')"
          :items="organisationNameItems"
          @input="page.refreshCompanies()"
        ></p-select>
      </p-col>
    </p-row>
    <p-list-view ref="ordersList" v-model="page.companiesList" :columns="page.companyColumns" :loading="page.companiesDataSource.isLoading">
      <template slot="table-actions">
        <!-- Refresh Button -->
        <p-button :disabled="isLoading" @click="page.refreshCompanies()">
          <p-icon>refresh</p-icon>
        </p-button>
        <!-- Add Company Button -->
        <router-link to="companies/create">
          <p-button :disabled="isLoading">
            <p-icon left>
              add
            </p-icon>
            {{ $t('page.companies.button.addCompany') }}
          </p-button>
        </router-link>
      </template>
      <!-- Edit Company Button -->
      <template #actions="{ row }">
        <p-button @click="page.gotoDetail(row.values.id)">
          <p-icon>edit</p-icon>
        </p-button>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Int64LookupModel from '@/src/services/v2/model/int64-lookup-model';
  import GetCompaniesRequestModel from '@/src/services/v2/model/get-companies-request-model';
  import CompaniesPage from './companies.vue.model';

  export default Vue.extend({
    props: {
      value: { type: [Number, Array], default: undefined },
    },
    data: () => ({
      page: new CompaniesPage(),
      organisationNameItems: [] as Int64LookupModel[],
      isLoading: false,
      innerValue: undefined,
    }),
    watch: {
      value: {
        handler(value) {
          this.innerValue = value;
        },
      },
    },
    async mounted() {
      this.page.initialize();
      this.isLoading = true;
      try {
        const req = new GetCompaniesRequestModel();
        const res = await this.$service.v2.api.tdg_Organisations.getOrganisationLookup(req);
        this.organisationNameItems = res.items.map((i: { data: any; }) => i.data);
      } catch (error) {
        this.$log.error('Failed to load organisations:');
      } finally {
        this.isLoading = false;
      }
    },

  });
</script>
