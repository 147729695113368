import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import RegisterRequestModel from './register-request-model';
import RegisterRequestDTO from '../_generated/dto/register-request-dto';
import PostCompanyRequestModel from './post-company-request-model';
import PostCompanyRequestDTO from '../_generated/dto/post-company-request-dto';
import PostUserRequestModel from './post-user-request-model';
import PostUserRequestDTO from '../_generated/dto/post-user-request-dto';

export default class CompanyAndUserRequestModelBase extends BaseModel<PostCompanyRequestDTO & RegisterRequestDTO & PostUserRequestDTO> {
  public company = new PostCompanyRequestModel();

  public user = new RegisterRequestModel();

  public tdg_User = new PostUserRequestModel();
}
