import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema = {
  ...queryBuilder.properties({
    companyName: { type: 'string', required: true },
    companyStreet: { type: 'string', required: true },
    companyZIP: { type: 'string', required: true },
    companyCity: { type: 'string', required: true },
    companyContactSalutation: { type: 'integer', required: true },
    companyContactFirstName: { type: 'string', required: true },
    companyContactLastName: { type: 'string', required: true },
    companyContactPhoneNumber: { type: 'string', pattern: validationPatterns.phone, required: true },
    companyContactEmail: { type: 'string', pattern: validationPatterns.email, required: true },
    companyLat: { type: 'number', required: true },
    companyLong: { type: 'number', required: true },
    acceptConditions: { type: 'boolean', required: true },
  }),
};

export default personalDetailsVueSchema;
