// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostCompanyRequestModel from '../../model/post-company-request-model';
import PostCompanyRequestDTO from '../dto/post-company-request-dto';
import dtoSchema from '../schemas/post-company-request-schema';

export default abstract class PostCompanyRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostCompanyRequestDTO>) {
    super();
    if (data) {
      this.accessToken = data.accessToken ?? undefined;
      this.companyName = data.companyName;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.description = data.description ?? undefined;
      this.website = data.website ?? undefined;
      this.employeesCount = data.employeesCount ?? undefined;
      this.apprenticesCount = data.apprenticesCount ?? undefined;
      this.apprenticeShipsCount = data.apprenticeShipsCount ?? undefined;
      this.jobs = data.jobs ?? undefined;
      this.companyContactSalutation = data.companyContactSalutation;
      this.companyContactFirstName = data.companyContactFirstName;
      this.companyContactLastName = data.companyContactLastName;
      this.companyContactPhoneNumber = data.companyContactPhoneNumber;
      this.companyContactEmail = data.companyContactEmail;
      this.companyStreet = data.companyStreet;
      this.companyHouseNumber = data.companyHouseNumber ?? undefined;
      this.companyZIP = data.companyZIP;
      this.companyCity = data.companyCity;
      this.companyLat = data.companyLat;
      this.companyLong = data.companyLong;
      this.acceptConditions = data.acceptConditions;
      this.companyUserId = data.companyUserId ?? undefined;
    }
  }
  /**
  */
  accessToken?: string;
  /**
  * @minimum 1
  */
  companyName?: string;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  */
  description?: string;
  /**
  */
  website?: string;
  /**
  * @type {int32}
  */
  employeesCount?: number;
  /**
  * @type {int32}
  */
  apprenticesCount?: number;
  /**
  * @type {int32}
  */
  apprenticeShipsCount?: number;
  /**
  */
  jobs?: string;
  /**
  * @type {int32}
  */
  companyContactSalutation?: 0 | 1 | 2; // SalutationDTO
  /**
  * @minimum 1
  */
  companyContactFirstName?: string;
  /**
  * @minimum 1
  */
  companyContactLastName?: string;
  /**
  * @minimum 1
  */
  companyContactPhoneNumber?: string;
  /**
  * @minimum 1
  */
  companyContactEmail?: string;
  /**
  * @minimum 1
  */
  companyStreet?: string;
  /**
  */
  companyHouseNumber?: string;
  /**
  * @minimum 1
  */
  companyZIP?: string;
  /**
  * @minimum 1
  */
  companyCity?: string;
  /**
  * @type {float}
  */
  companyLat?: number;
  /**
  * @type {float}
  */
  companyLong?: number;
  /**
  */
  acceptConditions?: boolean;
  /**
  * @type {int64}
  */
  companyUserId?: number;

  static toModel(dto: DeepPartial<PostCompanyRequestDTO>): PostCompanyRequestModel;
  static toModel(dto: DeepPartial<PostCompanyRequestDTO> | undefined | null): PostCompanyRequestModel | undefined;
  static toModel(dto: DeepPartial<PostCompanyRequestDTO> | undefined | null): PostCompanyRequestModel | undefined {
    return dto ? new PostCompanyRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostCompanyRequestModel> | PostCompanyRequestModel): PostCompanyRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      accessToken: unwrapped.accessToken,
      companyName: unwrapped.companyName,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      description: unwrapped.description,
      website: unwrapped.website,
      employeesCount: unwrapped.employeesCount,
      apprenticesCount: unwrapped.apprenticesCount,
      apprenticeShipsCount: unwrapped.apprenticeShipsCount,
      jobs: unwrapped.jobs,
      companyContactSalutation: unwrapped.companyContactSalutation,
      companyContactFirstName: unwrapped.companyContactFirstName,
      companyContactLastName: unwrapped.companyContactLastName,
      companyContactPhoneNumber: unwrapped.companyContactPhoneNumber,
      companyContactEmail: unwrapped.companyContactEmail,
      companyStreet: unwrapped.companyStreet,
      companyHouseNumber: unwrapped.companyHouseNumber,
      companyZIP: unwrapped.companyZIP,
      companyCity: unwrapped.companyCity,
      companyLat: unwrapped.companyLat,
      companyLong: unwrapped.companyLong,
      acceptConditions: unwrapped.acceptConditions,
      companyUserId: unwrapped.companyUserId,
    } as PostCompanyRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostCompanyRequestModel, can not map to PostCompanyRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
