import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema: IJSONSchema = {
  type: 'object',
  properties: {
    organisation: {
      type: 'object',
      properties: {
        organisationName: { type: 'string', minLength: 1 },
        membersCount: { type: 'number', minimum: 1 },
        organisationContactSalutation: { type: 'integer', minimum: 0 },
        organisationContactFirstName: { type: 'string', minLength: 1 },
        organisationContactLastName: { type: 'string', minLength: 1 },
        organisationContactPhoneNumber: { type: 'string', pattern: validationPatterns.phone },
        organisationContactEmail: { type: 'string', pattern: validationPatterns.email },
        organisationStreet: { type: 'string', minLength: 1 },
        organisationZIP: { type: 'string', minLength: 1 },
        organisationCity: { type: 'string', minLength: 1 },
        acceptConditions: { type: 'boolean' },
      },
      required: ['organisationName', 'membersCount', 'organisationContactSalutation', 'organisationContactFirstName', 'organisationContactLastName', 'organisationContactPhoneNumber', 'organisationContactEmail', 'organisationStreet', 'organisationZIP', 'organisationCity', 'acceptConditions'],
    },
    user: {
      type: 'object',
      properties: {
        email: { type: 'string', pattern: validationPatterns.email },
        password: { type: 'string', minLength: 6 },
        repeatPassword: { type: 'string', minLength: 6 },
      },
      required: ['email', 'password', 'repeatPassword'],
    },
  },
  required: ['organisation', 'user'],
};

export default personalDetailsVueSchema;
