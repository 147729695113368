import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema = {
  ...queryBuilder.properties({
    organisationName: { type: 'string', required: true },
    membersCount: { type: 'number', required: true },
    organisationContactSalutation: { type: 'integer', required: true },
    organisationContactFirstName: { type: 'string', required: true },
    organisationContactLastName: { type: 'string', required: true },
    organisationContactPhoneNumber: { type: 'string', pattern: validationPatterns.phone, required: true },
    organisationContactEmail: { type: 'string', pattern: validationPatterns.email, required: true },
    organisationStreet: { type: 'string', required: true },
    organisationZIP: { type: 'string', required: true },
    organisationCity: { type: 'string', required: true },
  }),
};

export default personalDetailsVueSchema;
