import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class LayoutDefaultPage extends PageBase {
  public isOrganisation = false;

  public isCompany = false;

  private isLoaded = false;

  public tdg_usersDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Users.getRegistrationTypeByUserId,
  });

  async initialize(): Promise<void> {
    const currentUser = await Vue.$auth.getUser();
    this.tdg_usersDataSource.filter.userId = currentUser?.id!;
    if (!this.isLoaded) {
      await this.tdg_usersDataSource.select();
      this.isLoaded = true;
    }
    await this.getRegistrationType();
  }

  public async getRegistrationType() {
    try {
      const registrationType = this.tdg_usersDataSource.data?.data.registrationType;
      this.isOrganisation = (registrationType === 'Organisation');
      this.isCompany = (registrationType === 'Company');

      const currentRoute = Vue.$router.currentRoute.path;
      let targetRoute = '';

      if (registrationType === 'Organisation') {
        targetRoute = '/tourdegwearb/organisation/dashboard';
      } else if (registrationType === 'Company') {
        targetRoute = '/tourdegwearb/company/dashboard';
      }

      // Weiterleitung nur durchführen, wenn die Zielroute sich von der aktuellen Route unterscheidet
      if (targetRoute && currentRoute !== targetRoute) {
        Vue.$router.push(targetRoute);
      }
    } catch (error) {
      Vue.$log.error(`Error refreshing companies: ${error}`);
    }
  }
}
