// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostUserRequestModel from '../../model/post-user-request-model';
import PostUserRequestDTO from '../dto/post-user-request-dto';
import dtoSchema from '../schemas/post-user-request-schema';

export default abstract class PostUserRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostUserRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
      this.registrationType = data.registrationType;
    }
  }
  /**
  * @type {int32}
  */
  userId?: number;
  /**
  * @minimum 1
  */
  registrationType?: string;

  static toModel(dto: DeepPartial<PostUserRequestDTO>): PostUserRequestModel;
  static toModel(dto: DeepPartial<PostUserRequestDTO> | undefined | null): PostUserRequestModel | undefined;
  static toModel(dto: DeepPartial<PostUserRequestDTO> | undefined | null): PostUserRequestModel | undefined {
    return dto ? new PostUserRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostUserRequestModel> | PostUserRequestModel): PostUserRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
      registrationType: unwrapped.registrationType,
    } as PostUserRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostUserRequestModel, can not map to PostUserRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
