import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/_admin/organisations',
      name: 'organisations',
      component: () => import('./views/pages/_admin/organisations/organisations.vue'),
    },
    {
      path: '/_admin/organisations/create',
      name: 'createOrganisations',
      component: () => import('./views/pages/_admin/organisations/createOrganisations.vue'),
    },
    {
      path: '/_admin/organisations/edit/:id',
      name: 'editOrganisation',
      component: () => import('./views/pages/_admin/organisations/editOrganisations.vue'),
    },
    {
      path: '/_admin/companies',
      name: 'companies',
      component: () => import('./views/pages/_admin/companies/companies.vue'),
    },
    {
      path: '/_admin/companies/create',
      name: 'createCompanies',
      component: () => import('./views/pages/_admin/companies/createCompanies.vue'),
    },
    {
      path: '/_admin/companies/edit/:id',
      name: 'editCompany',
      component: () => import('./views/pages/_admin/companies/editCompanies.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/_widget/companies/registration',
      name: 'companyRegistration',
      component: () => import('./views/pages/_widget/companyRegistration.vue'),
    },
    {
      path: '/_admin/testing/widget/companies/registration',
      name: 'companyRegistrationTesting',
      component: () => import('./views/pages/_admin/testing/widget/companyRegistrationTesting.vue'),
    },
    {
      path: '/_widget/organisations/registration',
      name: 'organisationRegistration',
      component: () => import('./views/pages/_widget/organisationRegistration.vue'),
    },
    {
      path: '/_admin/testing/widget/organisations/registration',
      name: 'organisationRegistrationTesting',
      component: () => import('./views/pages/_admin/testing/widget/organisationRegistrationTesting.vue'),
    },
    {
      path: '/tourdegwearb/organisation/dashboard',
      name: 'organisationDashboard',
      component: () => import('./views/pages/forOrganisations/organisationDashboard.vue'),
    },
    {
      path: '/tourdegwearb/company/dashboard',
      name: 'companyDashboard',
      component: () => import('./views/pages/forCompanies/companyDashboard.vue'),
    },
  ],
};
