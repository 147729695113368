import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class OrganisationsPage extends PageBase {
  public get organisationsList() {
    return this.organisationsDataSource.data?.items.map((i) => {
      return {
        ...i.data,
        isActiveLabel: i.data.isActive ? 'Aktiv' : 'Inaktiv',
      };
    }) || [];
  }

  public set organisationsList(value) { /* Do nothing */ }

  public organisationsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisations,
  });

  async initialize(): Promise<void> {
    this.organisationsDataSource.filter.searchCriteria = '';
    await this.organisationsDataSource.select();
  }

  public organisationColumns = [
    { value: Vue.$t('page.organisations.table.id'), textAlign: 'left', field: 'id' },
    { value: Vue.$t('page.organisations.table.orgsanisationName'), textAlign: 'left', field: 'organisationName' },
    { value: Vue.$t('page.organisations.table.organisationContactFirstName'), textAlign: 'left', field: 'organisationContactFirstName' },
    { value: Vue.$t('page.organisations.table.organisationContactLastName'), textAlign: 'left', field: 'organisationContactLastName' },
    { value: Vue.$t('page.organisations.table.organisationContactPhoneNumber'), textAlign: 'left', field: 'organisationContactPhoneNumber' },
    { value: Vue.$t('page.organisations.table.organisationContactEmail'), textAlign: 'left', field: 'organisationContactEmail' },
    { value: Vue.$t('page.organisations.table.isActive'), textAlign: 'left', field: 'isActiveLabel' },
    { value: Vue.$t('page.organisations.table.externalId'), textAlign: 'left', field: 'externalId' },
    { value: '', field: 'actions' },
  ];

  gotoDetail(id: string | number) {
    Vue.$router.push(`/_admin/organisations/edit/${id}`);
  }

  async refreshOrganisations() {
    try {
      await this.organisationsDataSource.select();
    } catch (error) {
      Vue.$log.error(`Error refreshing organisations: ${error}`);
    }
  }

  copyAccessToken(row: any) {
    const { externalId } = row;

    if (!externalId) {
      Vue.$log.error('No access token found for this organisation');
      return;
    }

    const tokenToCopy = typeof externalId === 'string'
      ? externalId
      : externalId.value || JSON.stringify(externalId);

    navigator.clipboard.writeText(tokenToCopy).then(() => {
      Vue.$log.info('Access token copied');
    }).catch((err) => {
      Vue.$log.error(`Failed to copy access token: ${err}`);
    });
  }
}
