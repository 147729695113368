<template>
  <div class="companyDashboard-page registrationSuccessWrapper">
    <h1>{{ $t("page.dashboard.companies.title") }}</h1>
    <p>{{ $t("page.dashboard.companies.text.abschnittEins") }}</p>
    <p>{{ $t("page.dashboard.companies.text.abschnittZwei") }}</p>
    <p>{{ $t("page.dashboard.companies.text.abschnittDrei") }}</p>
    <p-row class="buttonWrapper" spacious>
      <p-col xs12 sm6 md4>
        <p-row dense>
          <p-col class="supportPhoneNumber" xs6>
            <a href="tel:+41556145280"><p-button stretch>+41 55 614 52 80</p-button></a>
          </p-col>
          <p-col class="supportEmail" xs6>
            <a href="mailto:support@gl-it.ch"><p-button stretch>Sende eine E-Mail</p-button></a>
          </p-col>
        </p-row>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CompanyDashboardPage from './companyDashboard.vue.model';

  export default Vue.extend({
    name: 'CompanyDashboard',
    data: () => ({
      page: new CompanyDashboardPage(),
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>

<style scoped>
  .companyDashboard-page {
    padding: 20px;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.6;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .supportPhoneNumber, .supportEmail {
    flex: 1;
    margin: 0 10px;
  }
</style>
