import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import personalDetailsVueSchema from './editCompanies.vue.schema';
import Tdg_CompanyModel from '../../../../services/v2/model/tdg-company-model';
import GetTdgDocumentByExternalIdRequestModel from '../../../../services/v2/model/get-tdg-document-by-external-id-request-model';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';

export default class EditCompaniesPage extends PageBase {
  public get company() { return this.companiesDataSource.data?.data ?? new Tdg_CompanyModel(); }

  public error?: Error | string = undefined;

  public organisationId: number | undefined = undefined;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyById,
    updateCommand: Vue.$service.v2.api.tdg_Companies.putCompany,
  });

  async initialize(): Promise<void> {
    const requestDocument = new GetTdgDocumentByExternalIdRequestModel();
    this.companiesDataSource.filter.id = this.organisationId;
    await this.companiesDataSource.select();
    if (this.company.logoDocumentExternalId) {
      requestDocument.externalId = this.company.logoDocumentExternalId;
      const document = await Vue.$service.v2.api.tdg_Documents.getTdgDocumentByExternalId(requestDocument);
      const fileContent = await Vue.$service.v2.api.tdg_Documents.downloadTdgDocument(requestDocument);
      const file = new File([fileContent.data], document.data.originalFileName || 'unknown Filename', { type: document.data.contentType });
      Vue.set(this, 'logoDocument', [file]);
    }
  }

  public editCompanies = async () => {
    const valid = this.company.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadTdgDocumentRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
        this.company.logoDocumentExternalId = documentId.data;
      } else {
        this.company.logoDocumentExternalId = undefined;
      }
      try {
        await this.companiesDataSource.update(this.company);
        Vue.$router.push('/_admin/companies');
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };
}
