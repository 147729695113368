// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/post-company-request-schema',
  type: 'object',
  required: ['companyName', 'companyContactSalutation', 'companyContactFirstName', 'companyContactLastName', 'companyContactPhoneNumber', 'companyContactEmail', 'companyStreet', 'companyZIP', 'companyCity', 'companyLat', 'companyLong', 'acceptConditions'],
  properties: {
    accessToken: {
      type: 'string',
      nullable: true,
    },
    companyName: {
      type: 'string',
      minLength: 1,
    },
    logoDocumentExternalId: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    website: {
      type: 'string',
      nullable: true,
    },
    employeesCount: {
      type: 'integer',
      nullable: true,
    },
    apprenticesCount: {
      type: 'integer',
      nullable: true,
    },
    apprenticeShipsCount: {
      type: 'integer',
      nullable: true,
    },
    jobs: {
      type: 'string',
      nullable: true,
    },
    companyContactSalutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    companyContactFirstName: {
      type: 'string',
      minLength: 1,
    },
    companyContactLastName: {
      type: 'string',
      minLength: 1,
    },
    companyContactPhoneNumber: {
      type: 'string',
      minLength: 1,
    },
    companyContactEmail: {
      type: 'string',
      minLength: 1,
    },
    companyStreet: {
      type: 'string',
      minLength: 1,
    },
    companyHouseNumber: {
      type: 'string',
      nullable: true,
    },
    companyZIP: {
      type: 'string',
      minLength: 1,
    },
    companyCity: {
      type: 'string',
      minLength: 1,
    },
    companyLat: {
      type: 'number',
    },
    companyLong: {
      type: 'number',
    },
    acceptConditions: {
      type: 'boolean',
    },
    companyUserId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
