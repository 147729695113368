<template>
  <div class="organisationDashboard-page registrationSuccessWrapper">
    <h1>{{ $t("page.dashboard.organisations.title") }}</h1>
    <p>{{ $t("page.dashboard.organisations.text.abschnittEins") }}</p>
    <p>{{ $t("page.dashboard.organisations.text.abschnittZwei") }}</p>
    <p>{{ $t("page.dashboard.organisations.text.abschnittDrei") }}</p>
    <p-row class="buttonWrapper" spacious>
      <p-col xs12 sm6 md4>
        <p-row dense>
          <p-col class="supportPhoneNumber" xs6>
            <a href="tel:+41556145280"><p-button stretch>{{ $t("page.dashboard.support.phoneNumber") }}</p-button></a>
          </p-col>
          <p-col class="supportEmail" xs6>
            <a href="mailto:support@gl-it.ch"><p-button stretch>{{ $t("page.dashboard.support.email") }}</p-button></a>
          </p-col>
        </p-row>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import OrganisationDashboardPage from './organisationDashboard.vue.model';

  export default Vue.extend({
    name: 'OrganisationDashboard',
    data: () => ({
      page: new OrganisationDashboardPage(),
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>

<style scoped>
  .organisationDashboard-page {
    padding: 20px;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.6;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .supportPhoneNumber, .supportEmail {
    flex: 1;
    margin: 0 10px;
  }
</style>
