<template>
  <p-container>
    <h2 class="mb-4">
      <p-button tertiary @click="$router.back()">
        <p-icon>keyboard_backspace</p-icon>
      </p-button>
      {{ $t('page.createCompany.title.addCompany') }}
    </h2>
    <p-row>
      <p-col>
        <p-row>
          <p-card>
            <p-row column>
              <p-col>
                <p-form :model="page.company">
                  <p-text-field v-model="page.company.accessToken" :label="$t('page.createCompany.label.externalId')" required></p-text-field>
                  <h3 class="mb-2">
                    {{ $t('page.createCompany.title.companyAdress') }}
                  </h3>
                  <p-text-field v-model="page.company.companyName" :label="$t('page.createCompany.label.companyName')" required></p-text-field>
                  <p-row>
                    <p-col xs12 sm10>
                      <p-text-field v-model="page.company.companyStreet" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
                    </p-col>
                    <p-col xs12 sm2>
                      <p-text-field v-model="page.company.companyHouseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
                    </p-col>
                  </p-row>
                  <p-row>
                    <p-col xs12 sm3>
                      <p-text-field v-model="page.company.companyZIP" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
                    </p-col>
                    <p-col xs12 sm9>
                      <p-text-field v-model="page.company.companyCity" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
                    </p-col>
                  </p-row>
                  <p-file-drop
                    v-model="page.logoDocument"
                    :label="$t('page.createCompany.label.LogoDocumentId')"
                    max="7"
                    accept="image/*"
                    single
                  ></p-file-drop>
                  <p-text-field v-model="page.company.website" :label="$t('page.createCompany.label.website')"></p-text-field>
                  <p-textarea v-model="page.company.description" :label="$t('page.createCompany.label.description')"></p-textarea>
                  <p-number-field v-model="page.company.employeesCount" :label="$t('page.createCompany.label.employeesCount')"></p-number-field>
                  <p-number-field v-model="page.company.apprenticesCount" :label="$t('page.createCompany.label.apprenticesCount')"></p-number-field>
                  <p-number-field v-model="page.company.apprenticeShipsCount" :label="$t('page.createCompany.label.apprenticeShipsCount')"></p-number-field>
                  <p-text-field v-model="page.company.jobs" :label="$t('page.createCompany.label.jobs')"></p-text-field>
                  <p-location
                    :default-latitude="47.1174765953623"
                    :default-longitude="9.149508327579966"
                    @latitudeChange="page.company.companyLat = $event"
                    @longitudeChange="page.company.companyLong = $event"
                  ></p-location>
                  <h3 class="mb-2">
                    {{ $t('page.createCompany.titel.companyContactDetails') }}
                  </h3>
                  <p-select v-model="page.company.companyContactSalutation" :label="$t('page.createCompany.label.companyContactSalutation')" :items="page.genderOptions()" required></p-select>
                  <p-text-field v-model="page.company.companyContactFirstName" :label="$t('page.createCompany.label.companyContactFirstName')" required></p-text-field>
                  <p-text-field v-model="page.company.companyContactLastName" :label="$t('page.createCompany.label.companyContactLastName')" required></p-text-field>
                  <p-phone-field v-model="page.company.companyContactPhoneNumber" :label="$t('page.createCompany.label.companyContactPhoneNumber')" required></p-phone-field>
                  <p-text-field v-model="page.company.companyContactEmail" :label="$t('page.createCompany.label.companyContactEmail')" required type="email"></p-text-field>
                  <p-checkbox v-model="page.company.acceptConditions" :label="$t('page.createCompany.label.conditions')" required>
                    <a>{{ $t('page.createCompany.label.acceptConditions') }}</a>
                    <a href="https://www.tour-de-gwaerb.ch/agb" target="_blank" rel="noopener noreferrer">{{ $t('page.createCompany.label.conditions') }}</a>
                  </p-checkbox>
                </p-form>
                <p-request-alert v-model="page.error"></p-request-alert>
                <p-row>
                  <p-button @click="$router.back()">
                    {{ $t('core.app.cancel') }}
                  </p-button>
                  <p-button @click="page.saveNewCompanies()">
                    {{ $t('core.app.save') }}
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-card>
        </p-row>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import pRow from '@glittr/frontend-core/src/components/p-row/p-row.vue';
  import Vue from 'vue';
  import CreateCompaniesPage from './createCompanies.vue.model';

  export default Vue.extend({
    components: { pRow },
    data: () => ({
      page: new CreateCompaniesPage(),
      isLoading: false,
      search: undefined,
      selected: undefined,
      latitude: 0 as Number,
      longitude: 0 as Number,
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>
